import { mande } from 'mande';
const apipath = process.env.NODE_ENV == 'development' ? 'http://localhost:9999/api/team' : '/api/team'
const wspath = process.env.NODE_ENV == 'development' ? 'localhost:9999/api/support' : '/api/support'
const request = mande(process.env.apihost || apipath)


// send
// const sends = async function(name, req){
//   req = req || {}
//   console.log('req', {name, req})
//   try {    
//     let resp = await request.post({name, req})
//     console.log('resp', {name, resp})    
//     return resp || {}

//   } catch (error) {
//     // console.error(error.body)
//     return {error:{id:'conn', code: 500}}    
//   }
// };

let send = async function(name, req){  
  if (!req) req = {}
  console.log('req', {name, req})
  req = JSON.stringify({name, req})  
  const request = new Request(apipath, {
    headers: {"Content-Type": "application/json"},
    method: "POST",
    body: req,
  });

  try {
    const response = await fetch(request)
    let result = await response.json() || {}
    console.log(result)
    return result
  } catch (error) {
    console.error("Error:", error);
    console.log("Error:", error);
    return {error:{id:'try',code:500, error: error}}
  }
}




// api
export default {

  async send(name,req) {return await send(name,req)},

  // support($route, $refs.supportMessage, console.logs)
  async support(route, message, logs) {return await send('support',{route, message, logs})},
  
  // team
  team:{ 
    async create(account) {return await send('team/create',{account})},
    async list() {return await send('team/list',{})},
    async update(id, k, v) {return await send('team/update',{id,k,v})},
    async updates(list) {return await send('team/updates',{list})}, //[]{id,k,v}
    async delete(id) {return await send('team/delete',{id})},
  },

  // news
  assets:{ 
    async create(news, channels) {return await send('asset/create',{news,channels})},
    async search(q) {return await send('asset/search',{q})},
    async update(id, k, v) {return await send('asset/update',{id, k,v})},
    async delete(id) {return await send('asset/delete',{id})},
  },

  // news
  accounts:{ 
    async add(links) {return await send('accounts/add',{links})},
    async update(id, k, v) {return await send('accounts/update',{id, k,v})},
    async search(q) {return await send('accounts/search',{q})},
    async delete(id) {return await send('accounts/delete',{id})},
  },

  image:{
    async delete(id, link) {return await send('assets/image/delete',{id, link})},
  },

  upload:{
    async logo(id, image){return await send('dict/upload/logo',{id, image})},
    // async image(id, image){return await send('upload/image',{id, image})},
    

    //file = image body or base64
    async image(file){
      return new Promise(function (resolve, reject) {
        const rs = new XMLHttpRequest();
        let host = `${apipath}/upload/image`;   
        console.log('upload/image',host)
        rs.open('post', host, true);
        rs.onload = function () {
          if (rs.status != 200)
            reject({ error: { id: 'try' } });
          if (!rs.response) resolve({});
          const resp = JSON.parse(rs.response);
          resolve(resp || {});
        };
        const uint8 = new Uint8Array(file);
        rs.send(uint8);
      });
    }, 

    async file(file, name){
      return new Promise(function (resolve, reject) {
        const rs = new XMLHttpRequest();
        let host = `${apipath}/upload/file?name=${name}`;        
        rs.open('post', host, true);
        rs.onload = function () {
          if (rs.status != 200)
            reject({ error: { id: 'try' } });
          if (!rs.response) resolve({});
          const resp = JSON.parse(rs.response);
          resolve(resp);
        };
        const uint8 = new Uint8Array(file);
        rs.send(uint8);
      });
    }, 

    async doc(file){
      return new Promise(function (resolve, reject) {
        const rs = new XMLHttpRequest();
        let host = `${apipath}/upload/doc`;        
        rs.open('post', host, true);
        rs.onload = function () {
          if (rs.status != 200)
            reject({ error: { id: 'try' } });
          if (!rs.response) resolve({});
          const resp = JSON.parse(rs.response);
          resolve(resp);
        };
        const uint8 = new Uint8Array(file);
        rs.send(uint8);
      });
    }, 
    
    
  },

  
  
}




