const wspath = process.env.NODE_ENV == 'development' ? 'localhost:9999/api/team/ws' : '/api/team/ws'
const wss = process.env.NODE_ENV == 'development' ? 'ws' : 'wss'

import api from '@/ts/api';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const WebsocketStore = defineStore('ws', {
  state: () => ({         
    chats: useStorage('stima_team_support_chats', {}), //chatID = messages
    list: useStorage('stima_team_support_list', []), //[] users chat

    conn: undefined,
    online: false,

  }),
  
  getters: {
    messages: (state) => (chat) => {
      let list = state.chats[chat]
      if (!list) return []
      list.forEach(p => {
        if (!p.id) p.id = Math.random().toString()
      });    
      return list.sort((a,b) => {
        if (a.created == b.created) return a.id < b.id
        return a.created < b.created
      })
    },
  },
  actions: {
    
    async refresh(){
      let res = await api.send('support/list',{})
      if (res.error) return      
      this.list = res.resp
      return {}
   },  

    clear(){
      this.list = []      
    },
    
    // global, all ws messages
    onMessage(r){
      console.log("ws/onmessage", r)
      if (r.error){
        console.log("ws/error", r.error)
        return
      }

      switch (r.name) {        
        case 'support/message':
          this.addMessage(r.resp.uid, r.resp)
          break;
        default: 
      }

    },
    
    async send(name, req){
      console.log('try ws/sending...', name, req)
      let conn = this.init()
      if (!conn) {
        console.log('ws/disconnect', conn)
        return {error:{id:'conn',c:'No connection', code: 500}}
      }
      conn.send({name, req})
      console.log('ws/sent', conn)
      return {}
    },

    setOnline(online){              
        this.online = online      
    },
    
    init(){   
      

      console.log('ws/init', this.conn)
      if (this.conn) return this.conn          
      this.conn = new WS(this.onMessage, this.setOnline)          
      return this.conn
    },

    addMessage(id, message){
      console.log('ws/addmessage',id, message)
      if (!this.chats[id]) this.chats[id] = []
      this.chats[id].push(message)
      // this.sortMessages(id)
    },
    addMessages(id, messages){      
      this.chats[id] = messages || []
      this.sortMessages(id)
    },
    getMessages(id){      
      return this.chats[id] || []
    },
    deleteMessage(chat, id){      
      let c = this.chats[chat] 
      if (!c) {
        console.log('ws/cant find chat', chat)
        return
      }      
      let index = c.findIndex((p) => p.id === id);
      if (index > -1) c.splice(index, 1);
    },
    
    updateMessageID(chat, sid, id){
      console.log('ws/update message', chat, sid, id)
      if (!this.chats[chat]) {
        console.log('ws cant find chat', chat, 'sid',sid, 'newid',id)
        return
      }
      let p = this.chats[chat].find(v => v.sid == sid)
      if (!p) {
        console.log('ws/cant message', sid, 'newid',id)
        return
      }
      p.id = id
      p.sid = undefined
      console.log('updated')
    },
    
    sortMessages(id){
      if (!this.chats[id]) return
      this.chats[id].sort((a,b) => a.created > b.created);      
    },


  },
})



class WS {
  constructor(onMessage, online) {
    this.url = `${wss}://${wspath}`
    this.connect(onMessage, online)    

    // const { status, data, send, open, close } = useWebSocket(`${wss}://${wspath}`, {
    //   autoReconnect: {
    //     retries: 10,
    //     delay: 5000,          
    //   },
    // })

  }
  
  /* eslint-disable */
  connect(onMessage, online){
      var self = this
      if (this.ws) return
      var ws = new WebSocket(this.url);

      // 0 connecting
      // 1 open
      // 2 closing
      // 3 closed
     
      // open
      ws.onopen = function() {
        online(true)
        console.log('websockets connected');        
      };
    
      // got message
      ws.onmessage = function(e) {        
        online(true)        
        let res = JSON.parse(e.data)
        console.log('websockets/got', res);
        onMessage(res)        
      };

      // reconnect func
      var reconnect = function (){ self.connect(onMessage)}
    
      // close
      ws.onclose = function(e) {
        online(false)
        console.log('websockets/reconnect');
        setTimeout(reconnect, 5000);
      };
    
      // error
      ws.onerror = function(err) {
        online(false)
        console.error('websockets:', err, 'Closing socket');
        ws.close();
        setTimeout(reconnect, 5000);
      };

      // object
      this.ws = ws

      // send func
      this.send = function(v) {
        console.log('websockets/send',v);
         ws.send(JSON.stringify(v))
      }
    
  }


}

// // ws
// export default {

//   create(onMessage) {
//     // // Create WebSocket connection.
//     // let link = `${wss}://${wspath}`
//     // console.log("ws link", link)
//     // const socket = new WebSocket(link);

//     // // Connection opened
//     // socket.addEventListener("open", (event) => {
//     //   socket.send(JSON.stringify({name:'connect'}));
//     // });

//     // // Listen for messages
//     // socket.addEventListener("message", (event) => {
//     //   console.log("Message from server ", event.data);
//     //   if (onMessage) onMessage(event.data)      
//     // });

//     return new WS(onMessage)

//   }

  
// }




