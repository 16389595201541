<template>
    <Head/>
    <router-view class="initbox" :key ='$route.params'></router-view>
    <!-- <Footer/> -->
</template>

<script setup>
import Head from './head.vue';
// import Footer from './footer.vue';
</script>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'App',
    data() {return {}},
    mounted() {
        // document.querySelector('body').classList.add('dark')
        // document.querySelector('body').classList.remove('dark')
    },

    });
</script>
<style>
   @import '../../css/index.css'; 

    .initbox{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100vw;
        min-height: 100vh; 
        padding-top: 40px;
    }

</style>