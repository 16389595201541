import api from '@/ts/api';
import { Dict } from '@/ts/dict';
import { Tasks } from '@/ts/tasks';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const Store = defineStore('store', {
  state: () => ({         
    profile: useStorage('stima_team_profile', {}),
    stats: useStorage('stima_team_stats', {}),
    supportTeam: useStorage('stima_support_team', []),

    online: false,
    socket: undefined,
  }),
  
  getters: {

    god: (state) => state.profile.god,
    
    access: (state) => (key) => {
      if (state.profile.god) return true
      if (!state.profile.access) return false
      if (state.profile.access[key]) return true
      return false
    },
    
    teamMember: (state) => (id) => {
      console.log('store/teamlist', state.supportTeam)
      if (state.profile.id == id) return state.profile
      return state.supportTeam.find(p => p.id == id)
    },
    
    menu: (state) => {
      if (state.profile.id) return {}
      let list = {} 
      for (let index = 0; index < state.profile.access.length; index++) {
          const item = state.profile.access[index];
          if (!item) continue
          let p = item.split('/')
          if (p.length < 2) continue
          list[p[0]] = true
      }
      return list
    }
      
  },

  actions: {
    
    async updateStats(){
      let res = await api.send('stats',{})
      if (res.error) return {}
      this.stats = res.resp
      return {}
   },
    
   async updateSupportTeam(){
      let res = await api.send('support/team',{})
      if (res.error) return {}
      this.supportTeam = res.resp
      return {}
   },
    
    async login(token){
       let res = await api.send('login',{token})
       if (res.error){
        console.error("empty res")
        return res
       }
       this.profile = res.resp
       return {}
    },
    
    async logout(){
       let res = await api.send('logout')
       if (res.error) return              
      //  dict
       const dict = Dict()
       dict.clear()
      //  tasks
       let tasks = Tasks()
       tasks.clear()
      //  this
       this.clear()
       window.location = "/"
       return {}
    },

    clear(){
      this.profile = {}
      this.stats = {}
    },

    print(any){
      console.log('print',any)
    }


  },
})

