// internal
import App from '@/page/app/index.vue';
import router from '@/ts/router';

// pinia
import { createPinia } from 'pinia';

// app
import { createApp } from 'vue';

// store
import { Dict } from '@/ts/dict';
import { Store } from '@/ts/store';
import { Support } from '@/ts/support';
import { Tasks } from '@/ts/tasks';
import { WebsocketStore } from '@/ts/ws';


// plugins
// import 'highlight.js/styles/vs2015.css';
// import 'highlight.js/styles/stackoverflow-dark.css';
// import 'highlight.js/styles/obsidian.css';
// import VueApexCharts from "vue3-apexcharts";
import vue3GoogleLogin from 'vue3-google-login';
// import VueHighlightJS from 'vue3-highlightjs';

// directives
import ColorDirective from "@/ts/directives/color";
import DragOverDirective from "@/ts/directives/dragover";
import DropDirective from "@/ts/directives/drop";
import OutsideClick from "@/ts/directives/outside";
import Tooltip from "@/ts/directives/tooltip";
import UploadDirective from "@/ts/directives/upload";

// api
import api from '@/ts/api';
import tools from '@/ts/tools.js';

// heads
// import Footer from '@/page/footer.vue';
// import Head from '@/page/head.vue';

// import Home from '@/page/home.vue';
// import Login from '@/page/login.vue';
// import TeamCreate from '@/page/team/create.vue';
// import Welcome from '@/page/welcome.vue';

// news
// import NewsCreate from '@/page/news/create.vue';
// import NewsEdit from '@/page/news/edit.vue';


// framework core
// import Active from '@/ui/core/active.vue';
import Box from '@/ui/core/box.vue';
import Button from '@/ui/core/button.vue';
import Color from '@/ui/core/color_preview.vue';
import Column from '@/ui/core/column.vue';
import Down from '@/ui/core/down.vue';
import Fix from '@/ui/core/fixbox.vue';
import Gap from '@/ui/core/gap.vue';
import Input from '@/ui/core/input.vue';
import Popup from '@/ui/core/popup.vue';
import Scroll from '@/ui/core/scroll.vue';
import Switch from '@/ui/core/switch.vue';
import Tag from '@/ui/core/tag.vue';


// framework some
import Row from '@/ui/core/row.vue';
import Tab from '@/ui/core/tab.vue';
import Tabs from '@/ui/core/tabs.vue';
import Text from '@/ui/core/text.vue';

// Local
import Block from '@/ui/block.vue';
import Check from '@/ui/check.vue';
import CheckValue from '@/ui/checkValue.vue';
import Error from '@/ui/core/error.vue';

// import City from '@/ui/cityName.vue';
// import File from '@/ui/file.vue';
// import Image from '@/ui/image.vue';
// import Loader from '@/ui/loader.vue';
// import Menu from '@/ui/menuitem.vue';
// import Select from '@/ui/select.vue';
// import Title from '@/ui/title.vue';
// import T from '@/ui/title2.vue';


// inputs
// import Brand from '@/ui/input/brands.vue';
// import Geo from '@/ui/input/geo.vue';
// import Number from '@/ui/input/number.vue';
// import Price from '@/ui/input/price.vue';
// import Year from '@/ui/input/year.vue';

// app
const app = createApp(App)

// pinia
app.use(createPinia())

// stores
app.config.globalProperties.db = Store()
app.config.globalProperties.tasks = Tasks()
app.config.globalProperties.dict = Dict()
app.config.globalProperties.support = Support()
app.config.globalProperties.ws = WebsocketStore()

// api
app.config.globalProperties.api = api
app.config.globalProperties.tools = tools

//google
app.use(vue3GoogleLogin, {clientId: '118124018522-niu5qaj16ei7s1nj78b1mmgv8ff9ffid.apps.googleusercontent.com'})

// is dev
app.config.globalProperties.dev = process.env.NODE_ENV == 'development'

// router
app.use(router);
router.isReady().then(() => {app.mount('#app')});

// charts
// app.use(VueApexCharts);


//code
// app.use(VueHighlightJS)

// upload
app.directive('upload', UploadDirective)
app.directive('color', ColorDirective)
app.directive('drop', DropDirective)
app.directive('dragover', DragOverDirective)
app.directive('out', OutsideClick)
app.directive('help', Tooltip)
// app.directive('tooltip', Tooltip);

// router helper
app.config.globalProperties.go = function(name: string) {router.push({name:name})}
app.config.globalProperties.golink = function(link: string) {router.push(link)}
app.config.globalProperties.is = function(name: string) {return router.currentRoute.value.name == name}
app.config.globalProperties.openlink = function(link: string) {window.open(link, '_blank');}

 
// framework
app.component('Box', Box);
app.component('Column', Column);
app.component('Row', Row);
app.component('Gap', Gap);
app.component('Buttons', Button);
app.component('Check', Check);
// app.component('Head', Head);
// app.component('Footer', Footer);
app.component('Popup', Popup);
app.component('Scroll', Scroll);
app.component('Down', Down);
app.component('Fix', Fix);
app.component('Text', Text);
app.component('Input', Input);
app.component('Tabs', Tabs);
app.component('Tab', Tab);
app.component('Error', Error);
// app.component('Active', Active);
// app.component('Login', Login);

// app.component('Home', Home);
// app.component('Welcome', Welcome);
app.component('Block', Block);
app.component('Switch', Switch);
app.component('Tag', Tag);
// app.component('Menu', Menu);
// app.component('Title', Title);
// app.component('Create', Create);
// app.component('Loader', Loader);
// app.component('Brands', Brands);
// app.component('Select', Select);
app.component('Color', Color);
app.component('Attr', Attr);
// app.component('Field', Field);
app.component('Image', Image);
app.component('File', File);
app.component('CheckValue', CheckValue);
// app.component('Category', Category);
// app.component('T', T);


// local
// app.component('AccountSearch', AccountSearch);
// app.component('AccountCreate', AccountCreate);
// app.component('TeamCreate', TeamCreate);
// app.component('AssetPreview', AssetPreview);

// news
// app.component('NewsCreate', NewsCreate);
// app.component('NewsEdit', NewsEdit);

// inputs
// app.component('Geo', Geo);
// app.component('Price', Price);
// app.component('Year', Year);
// app.component('Number', Number);
// app.component('Brand', Brand);

// city with flag
// app.component('City', City);
// app.component('AccountPage', AccountPage);
// app.component('AccountPageAPI', AccountPageAPI);




// clipboard
app.config.globalProperties.copyText = async function(text: string) { await navigator.clipboard.writeText(text)}




