// tools function
import { humanize } from 'humanize';
import DOMPurify from "isomorphic-dompurify";
import { marked } from "marked";
export default {

  size(bytes){
    return humanize.filesize(bytes)
  },
 
  ext(filename){
    return filename.split('.').pop()
  },
  // let size = await imageSize(file)
  // `{$size.width} × {$size.height} `
  imageSize(file) {
    return new Promise (function (resolved, rejected) {
      var i = new Image()
      i.onload = function(){
        resolved({width: i.width, height: i.height, box: i.width == i.height, vertical: i.width < i.height})
      };
      i.src = file
    })
  },

  async readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = e => {resolve(e.target.result)};
        reader.readAsDataURL(file);
    });
  },

  async readFileAsText(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = e => {resolve(e.target.result)};
        reader.readAsText(file);
    });
  },

  async readFileAsBytes(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = e => {resolve(e.target.result)};
        reader.readAsArrayBuffer(file);
    });
  },
  
  year(){
    let n = new Date()
    return n.getFullYear()
  },

  title(t) {
    if (!t) return 
    return t.charAt(0).toUpperCase() + t.slice(1);
  },

  textlimit(line, max) {
    return line.substring(0, max) + '...'
  },
  cut(line, max) {
    if (!line) return
    if (line.length <= max) return line
    return line.substring(0, max) + '...'
  },

  push(list, item) {
    if (!list) return
    let id = list.indexOf(item)
    id > -1 ? list.splice(id, 1) : list.push(item)
  },

  has(list, item) {
    if (!list) return
    return list.indexOf(item) > -1
  },

  len(list) {
    if (!list) return
    return list.length
  },

  delete(list, id) {
    if (!list) return
    let pos = list.indexOf(id)
    if (pos > -1) {
      list.splice(pos, 1);
    }
  },

  move(from, to, id) {
    for (var i = 0; i < from.length; i++) {
      if (from[i].id == id) {
        to.unshift(from[i]);
        from.splice(i, 1);
        i--;
        return
      }
    }
  },

  // format numbers
  fm(num, delim, k) {
    if (isNaN(num)) return ''
    if (k) {
      num = Math.round(num / 1000)
      return num + 'k'
    }
    if (!delim) delim = ' '
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delim)
  },

  //use:
  //many(42, 'найдена', 'найдено', 'найдены');
  many(num, one, five, many) {    
    let cases = [2, 0, 1, 1, 1, 2];
    let id = (num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]
    switch (id) {
      case 0: return one
      case 1: return five
      case 2: return many
    }
  },

  // now date
  now(){
    let d = new Date()
    return d
  },

  unix(){
    return Math.floor((new Date).getTime()/1000)
  },

  timeonly(unix){
    let d = new Date(0)
    d.setUTCSeconds(unix)
    let h = d.getHours()
    let m = d.getMinutes()
    if (m < 10) m = `0${m}`
    return `${h}:${m}` 
  },

  ago(unix, lang, customtraslate) {
    let langs = {
        en:{now:'',ago:'',next:'in',sec:'s',min:'min',hours:'h',days:'d',month:'m', years:'y'},
    }
    if (!langs[lang]) lang = 'en'
    let translate = langs[lang]
    if (customtraslate) translate = customtraslate
    
    let tt = new Date()
    var sec = Math.round((tt.getTime() / 1000) - unix)
    let back = translate.ago
    let front = translate.next;
    if (sec < 0) {
      sec = sec * -1
      back = ''
    } else {
      front = ''
    }

    if (sec < 0) return translate.now
    if (sec > 0 && sec < 60) return `${front} ${sec}${translate.sec} ${back}`
    if (sec > 60 && sec < 60 * 60) {
      let m = Math.round(sec / 60)
      return `${front} ${m} ${translate.min} ${back}` 
    }
    if (sec >= 60 * 60 && sec < 60 * 60 * 24) {
      let m = Math.round(sec / (60 * 60))
      return `${front} ${m} ${translate.hours} ${back}`
    }
    if (sec >= 60 * 60 * 24 && sec < 60 * 60 * 24 * 30) {
      let m = Math.round(sec / (60 * 60 * 24))
      return `${front} ${m} ${translate.days} ${back}`
    }
    if (sec >= 60 * 60 * 24 * 30 && sec < 60 * 60 * 24 * 365) {
      let m = Math.round(sec / (60 * 60 * 24 * 30))
      return `${front} ${m} ${translate.month} ${back}`
    }
    if (sec >= 60 * 60 * 24 * 365) {
      let m = Math.round(sec / (60 * 60 * 24 * 365))
      return `${front} ${m} ${translate.years} ${back}`
    }
  },

  openlink(url){
    let win = window.open(url, '_blank');
    win.focus();
  },

  popuplink(link, w,h){
    if (!link) return
    if (!w) w = 800
    if (!h) h = 600
    let size = `width=${w},height=${h}`
    window.open(link, "source", size);
    window.close();
  },

  // window width
  width() {
    var myWidth = 0;
    if( typeof( window.innerWidth ) == 'number' ) {
        myWidth = window.innerWidth;
    } else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
        myWidth = document.documentElement.clientWidth;
    } else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
        myWidth = document.body.clientWidth;
    }
    return myWidth
  },

  // window height
  height() {
    var myHeight = 0;
    if( typeof( window.innerWidth ) == 'number' ) {
        myHeight = window.innerHeight;
    } else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
        myHeight = document.documentElement.clientHeight;
    } else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
        myHeight = document.body.clientHeight;
    }
    return myHeight
  },

  deleteAllCookies() {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) { let cookie = cookies[i]; let eqPos = cookie.indexOf("="); let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie; document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";}
},

cookie(name) {
  name = name + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
},

  // refresh page
  refresh() {
    window.location.reload()
  },

  translit(text){
    var answer = '';
    var converter = {
      'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
      'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
      'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
      'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
      'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
      'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
      'э': 'e',    'ю': 'yu',   'я': 'ya',
      'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
      'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
      'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
      'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
      'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
      'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
      'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya'
    };
   
    for (var i = 0; i < text.length; ++i ) {
      if (converter[text[i]] == undefined){
        answer += text[i];
      } else {
        answer += converter[text[i]];
      }
    }
   
    return answer;
  },

  norm(text){
    //https://stackoverflow.com/questions/11815883/convert-non-ascii-characters-umlauts-accents-to-their-closest-ascii-equiva
    var combining = /[\u0300-\u036F]/g; 
    return text.normalize('NFKD').replace(combining, '')
  },

  sid(v){    
    if (!v) return
    v = v.replace(/[^a-z0-9]/gi, '')
    v = v.toLowerCase()
    return v
  },

  nospaces(text){
    return text.replace(/ /g,'')
  },

  filesize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
  },

  // search all links and make a href
  urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  },

  sanitizeHTML(html){
    let clean = DOMPurify.sanitize(html);
    return clean
  },

  // get html
  // https://marked.js.org/using_advanced
  markdown(text, options){
    if (!text) return ''
    let res = this.sanitizeHTML(
      marked(
        text
      )
    )
    return res
    
  },

  // clone var ()
  clone(x){   
    if (!x) return
    return JSON.parse(JSON.stringify(x));  
  },


}