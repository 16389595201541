import { Store } from '@/ts/store';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  
  
  {path: '/',         name: 'home',    component: () => import('@/page/index.vue')},  
  
  {path: '/jobs',  name: 'jobs',  beforeEnter: [logged], component: () => import('@/page/jobs/index.vue')},
  {path: '/market',  name: 'market',  beforeEnter: [logged], component: () => import('@/page/market/index.vue')},
  {path: '/resources',  name: 'resources',  beforeEnter: [logged], component: () => import('@/page/resources/index.vue')},

  {path: '/profile',  name: 'profile',  beforeEnter: [logged], component: () => import('@/page/profile/private/index.vue')},
  
  // {path: '/support',  name: 'support',  beforeEnter: [logged], component: () => import('@/page/support/index.vue'),
  //   children:[
  //     {path: ':id',  name: 'support/chat',  beforeEnter: [logged], component: () => import('@/page/support/chat.vue')},   
  //   ],
  // },
  // {path: '/support/:id',  name: 'support/chat',  beforeEnter: [logged], component: () => import('@/page/support/chat.vue')},  
  // {path: '/support/user/:id',  name: 'support/user',  beforeEnter: [logged], component: () => import('@/page/support/user.vue')},
  
  // {path: '/tasks',  name: 'tasks',  beforeEnter: [logged], component: () => import('@/page/tasks/index.vue')},
  // {path: '/tasks/:id',  name: 'task',  beforeEnter: [logged], component: () => import('@/page/tasks/task.vue')},

  // {path: '/developers',  name: 'developers', component: () => import('@/page/developers/index.vue')},
  
  {path: '/team',  name: 'team',  beforeEnter: [logged,god], component: () => import('@/page/team/index.vue'),   },
  
   
  
  // news
   {path: '/news',      name:'news', beforeEnter: [logged], component: () => import('@/page/news/index.vue')},
  
  
  // docs
  //  {path: '/docs',      name:'docs', beforeEnter: [logged], component: () => import('@/page/docs/index.vue')},
  //  {path: '/doc/:id',  name: 'docs/page',  beforeEnter: [logged],  component: () => import('@/page/docs/view.vue')},   

  
  
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


function notlogged(to: any, from: any, next: any) {
  const db = Store()
  const profile: any = db.profile
  if (!profile.id){
    next()
  } else{
    next({ name: 'profile'})
  }
}


function logged(to: any, from: any, next: any) {
  const db = Store()
  const profile: any = db.profile
  if (profile.id){
    next()
  } else{
    next({ name: 'home' })
  }
}

function god(to: any, from: any, next: any) {
  const db = Store()
  // const profile: any = db.profile
  console.log("god", db.profile)
  if (db.god){
    next()
  } else{
    next({ name: 'home' })
  }
}


export default router
