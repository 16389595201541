import api from '@/ts/api';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const Tasks = defineStore('tasks', {
  state: () => ({         
    list: useStorage('stima_team_tasks', []),    
  }),
  
  getters: {
      // 
  },
  actions: {
    
    async refresh(){
      let res = await api.send('tasks/list',{})
      if (res.error) return      
      this.list = res.resp
      return {}
   },
    

    clear(){
      this.list = []      
    },


  },
})

