import api from '@/ts/api';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const Dict = defineStore('dict', {
  state: () => ({
    attr: attrStore(), 
    team: teamStore(),    

    types: useStorage('stima_types', []), //attr field type    
    countries: useStorage('stima_country', []), 
    status: useStorage('stima_status', []), 
    currency: useStorage('stima_currency', []), 
    color: useStorage('stima_mycolor', ''), 
    accounts: useStorage('stima_myaccs', {}), //my accounts

    db: [],    
    stats: [],
  }),
  
  getters: {   
    
    year(){
      const d = new Date();
      return d.getFullYear()
    },

    // get all fields for asset category
    form: (state) => (id) => {      
      let attr = this.attr.list(id)
      let fields = this.attr.fields(id)
      if (!fields.length) fields = this.fields
      return fields.push(...attr)
    },

    root: (state) => {
      return {
        all: state.db.filter(item => !item.parent && item.name == 'root').sort((a, b) => a.title.localeCompare(b.title)),
        active: state.db.filter(item => !item.parent && item.name == 'root' && item.active).sort((a, b) => a.title.localeCompare(b.title)),
        inactive: state.db.filter(item => !item.parent && item.name == 'root' && !item.active).sort((a, b) => a.title.localeCompare(b.title)),
      }        
    },
    
    roots: (state) => {
      return state.db.filter(item => !item.parent && item.name == 'root').sort((a, b) => a.title.localeCompare(b.title))              
    },
    
    getRoot: (state) => (id) => {
      return state.root.all.find(item => item.id == id)
    },
    rootName: (state) => (id) => {
      let p = state.root.all.find(item => item.id == id)
      if (!p) return id
      return p.title
    },

    getRootMeta: (state) => (id) => {
      let r = state.root.all.find(item => item.id == id)
      if (!r) return {}
      return r.meta || {}
    },
    
    metaField: (state) => (root, k) => {
      let r = this.root.all.find(item => item.id == root)
      if (!r) return {}
      let res = r.meta || {}
      return res[k]
    },
    
    
    list: (state) => (parent, name, limit) => {
        let c = 0;
        let list = parent ? state.db.filter(item => item.parent == parent && item.name == name) : state.db.filter(item => item.name == name)
        if (limit && list && list.length > limit) list = list.slice(0, 10)
        return list //.sort((a, b) => a.title.localeCompare(b.title)) || []      
    },

    active: (state) => (parent, name, limit) => {
        let c = 0;
        let list = parent ? state.db.filter(item => item.active && item.parent == parent && item.name == name) : state.db.filter(item => item.active && item.name == name)
        if (limit && list && list.length > limit) list = list.slice(0, 10)
        return list//.sort((a, b) => a.title.localeCompare(b.title)) || []      
    },

    inactive: (state) => {      
      return (parent, name, limit) => {
        let c = 0;
        let list = parent ? state.db.filter(item => !item.active && item.parent == parent && item.name == name) : state.db.filter(item => !item.active && item.name == name)
        if (limit && list && list.length > limit) list = list.slice(0, 10)
        return list.sort((a, b) => a.title.localeCompare(b.title)) || []
      }
    },

    // count: (state) => (parent, name) => { state.db.filter(item => item.parent == parent && item.name == name).length },
    count: (state) => (parent, name) => { state.db.filter(item => item.parent == parent && item.name == name).length },

    countActive: (state) => (parent, name) => {parent ? state.db.filter(item => item.active && item.parent == parent && item.name == name).length : state.db.filter(item => item.active && item.name == name).length                },

    // get(id)
    get: (state) => (id) => state.db.find((item) => item.id === id) || {},
    
    // getType('mm')
    getType: (state) => (id) => state.types.find((item) => item.id === id) || {},

    // sid(sid)
    sid: (state) => (sid) => state.db.find((item) => item.sid == sid) || {},

    // brand(cat.id)
    brand: (state) => (id) => state.db.find((item) => item.id == id && item.name == 'brands'),
    
    

    // models(cat.id)
    models: (state) => (id) => state.db.filter(item => item.parent == id && item.name == 'models').sort((a, b) => a.title.localeCompare(b.title)),
    
    // models(cat.id)
    model: (state) => (id) => state.db.find(item => item.id == id && item.name == 'models'),
    
    // submodels(cat.id)
    submodels: (state) => (id) => state.db.filter(item => item.parent == id && item.name == 'submodels'),
    
    // models(cat.id)
    features: (state) => (id) => state.db.filter(item => item.parent == id && item.name == 'features'),
    
    virtualStatus: (state)  => state.status.filter(item => item.virtual),
    realStatus: (state) => state.status.filter(item => !item.virtual).sort((a, b) => a.id - b.id),
    
    statusName: (state) => (id) => {
      let c = state.status.find(item => item.id == id)
      if (!c) return ''
      return  c.title
    },
    countryName: (state) => (id) => {
      let c = state.countries.find(item => item.id == id)
      if (!c) return ''
      return c.title || ''
    },
    
    
    providers(){return {0:'Apple',1:'Google',2:'Email',3:'Private'}},

    dept() {
      return {
        list:['dev','design','market','exe','fin','other'],
        title:{ dev:'Dev', design:'Design', market:'Marketing', exe:'Executive', fin:'Finance', other:'Other'},
      }      
    },


  },
  
  actions: {

    
    async refresh(){

        this.refreshDicts()        
        this.refreshStats()        
        this.refreshStatic()

        this.attr.refresh()
        this.team.refresh()
   },

    async refreshDicts(){
        let res = await api.send('dict/list',{})
        if (res.error) return
        
        this.db = res.resp || []          
   },

    async refreshStatic(){
        let res = await api.send('dict/static',{})
        if (res.error) return
        
        this.countries = res.resp.country || []
        this.status = res.resp.status || []
        this.types = res.resp.types || []   
        this.currency = res.resp.currency || []   
                
   },

   
    async create(parent, name, list){
        let res = await api.send('dict/create',{parent, name, list})
        if (res.error) return res
        this.refresh()   
        return res
    },
      
    async update(id, k, v){
        let res = await api.send('dict/update',{id, k, v})
        if (res.error) return res
        this.refresh()   
        return res
    },

    async updateMeta(id, k, v){
        let res = await api.send('dict/update/meta',{id, k, v})
        if (res.error) return res
        this.refresh()   
        return res
    },

      async delete(id){
        let res = await api.send('dict/delete',{id})
        if (res.error) return res      
        this.refresh()   
        return res
    },

   async refreshStats(){
      let res = await api.send('stats',{})
      if (res.error) return
      this.stats = res.resp || {}
      return {}
  },

  // models(cat.id)
  async brands(asset, limit, offset) {
        let res = await api.send('dict/brands',{asset, limit, offset})
        if (res.error) return res
        return res.resp
    },

    clear(){
      this.attr.clear()
      this.team.clear()
      this.types = []
      this.countries = []
      this.status = []
      this.currency = []
      this.color = ''
      this.accounts = {}
      this.db = []   
      this.stats = []
    },
     
  },



})



// attr
const attrStore = defineStore('attr', {
  state: () => ({ 
    db: [],
  }),
  getters: {
    
    // get(id)
    get: (state) => (id) => state.db.find((item) => item.id === id) || {},
    
    // field(id, name)
    field: (state) => (id,name) => state.db.find((item) => item.cat == id && item.name == name) || {},
    
    // field(id, name)
    has: (state) => (id,name) => {
      let item = state.db.find((item) => item.cat == id && item.name == name)
      if (item) return true
      return false
    },

    count:(state) => (id) => state.db.filter(item => item.cat == id).length,
    
    // list(cat.id)
    list: (state) => (id) => {
        let list = state.db.filter(item => item.cat == id)        
        return list.sort((a, b) => a.id > b.id)      
    },

    // recurrency dicts list
    dicts: (state) => (id) => {
        let list = state.db.filter(item => item.asset == id && (item.type == 'list' || item.type == 'cat'))        
        return list.sort((a, b) => a.id > b.id)      
    },
    
    
  },

  actions: {
    
      clear(){
        this.db = []
      },

      // refresh
      async refresh(){
          let res = await api.send('attr/list',{})
          if (res.error) return   
          this.db = res.resp || []
          await this.reindexAttr()
          return {}
      },

      async reindexAttr(){
        /* 
            switch      //title{Switch} desc{Yes/No field}  #standart 
            num         //title{Number} desc{Any number}  #standart
            text        //title{Text} desc{Text input, best for titles etc}  #standart
            md          //title{Markdown} desc{Big text with markdown format, best for descriptions etc}  #standart
            url         //title{Link} desc{User could add link to website etc}  #standart
            price       //title{Price} desc{Fields for prices}  #standart
            year        //title{Year} desc{Special type: user can select year}  #standart
            date        //title{Date} desc{Special type: user can select exactly date: 14 March 1985 etc}  #standart

            country     //title{Country} desc{Country only}  #location
            city        //title{City} desc{Country, State, City select only}  #location
            address     //title{Address} desc{Full address: Country, State, City, ZIP, Address}  #location

            image       //title{Image} desc{User could upload image}  #files
            file        //title{File} desc{User could upload any file}  #files
            doc         //title{Document} desc{User could upload any documents: .doc, .xls, .txt, .rtf etc}  #files

            list        //title{List} desc{List of values}  #special
            cat         //title{Category} desc{Special type: user must select category}  #special

            mm          //title{mm} desc{Millimeters}  #metric
            cm          //title{cm} desc{Centimeters}  #metric
            m           //title{m} desc{Meters}  #metric
            g           //title{g} desc{Grams}  #metric
            kg          //title{kg} desc{Kilograms}  #metric
            ml          //title{ml} desc{Milliliters}  #metric
            l          //title{l} desc{Liters}  #metric
            m2         //title{m2} desc{Square Meters}  #metric
            km         //title{km} desc{Kilometers}  #metric
            mi         //title{miles} desc{Miles}  #metric
            kmh        //title{km/h} desc{km/h}  #metric
            hp        //title{hp} desc{Horse Powers}  #metric
            ft        //title{ft} desc{Foots}  #metric
            hrs        //title{hrs} desc{Hours}  #metric 
          */

          if (!this.db) return
          this.db.forEach(item => {
            if (!item.active) return
            if (item.custom) {item.vartype = 'string'; return}
            if (item.multi) {item.vartype = '[]int'; return}
            
            switch (item.type) {
              case 'text': 
              case 'url':
              case 'md':
                item.vartype = 'string'
                console.log('attr item type', item.type,">",item.vartype)
                return
              case 'image':
              case 'file':
              case 'doc':
                item.vartype = 'string'
                item.link = true
                item.file = true
                console.log('attr item type', item.type,">",item.vartype)
                return
              case 'list':
              case 'cat':
              case 'num':
              case 'year':
              case 'date':
                item.vartype = 'int'
                item.numbers = true
                console.log('attr item type', item.type,">",item.vartype)
                return
              case 'price':
                item.vartype = 'int'
                item.numbers = true
                item.currency = true
                console.log('attr item type', item.type,">",item.vartype)
                return
              case 'mm':
              case 'cm':
              case 'm':
              case 'g':
              case 'kg':
              case 'ml':
              case 'l':
              case 'm2':
              case 'km':
              case 'mi':
              case 'kmh':
              case 'hp':
              case 'ft':
              case 'hrs':
                item.vartype = 'int'
                item.metric = true
                item.numbers = true
                console.log('attr item type', item.type,">",item.vartype)
                return
              case 'switch':
                item.vartype = 'bool'
                console.log('attr item type', item.type,">",item.vartype)
                return
              case 'country':
              case 'city':
              case 'address':
                item.vartype = 'location'                
                item.location = true
                console.log('attr item type', item.type,">",item.vartype)
                return             
              default:
                return
            }
          });

      },
      
      // create simple (add attr)
      async create(root, cat, list, active){
          let res = await api.send('attr/create',{root, cat, list, active})
          if (res.error) return res
          this.refresh()
          return res
      },
      
      // create full model {root, name, cat, title...}
      async createModel(model){
          let res = await api.send('attr/create/model',model)
          if (res.error) return res
          this.refresh()   
          return res
      },
      

      // update
      async update(id, k, v){
          let res = await api.send('attr/update',{id, k, v})
          if (res.error) return res
          this.refresh()   
          return res
      },
      
      // update meta
      async updateMeta(id, k, v){
          let res = await api.send('attr/update/meta',{id, k, v})
          if (res.error) return res
          this.refresh()   
          return res
      },

      // updates
      async updates(id, keys){
          let res = await api.send('attr/updates',{id, keys})
          if (res.error) return res
          this.refresh()   
          return res
      },

      // delete
        async delete(id){
          let res = await api.send('attr/delete',{id})
          if (res.error) return res      
          this.refresh()   
          return res
      },

      
  },


})


// team
const teamStore = defineStore('team', {
  state: () => ({     
    db: useStorage('stima_team', []),
  }),
  getters: {
    
    // get(id)
    get: (state) => (id) => state.db.find((item) => item.id === id) || {},
    
    // list
    list: (state) => state.db,
    
    name: (state) => (id) => {
      let member = state.db.find((item) => item.id === id)
      if (!member) return {}
      return member.name || member.email
    },
    
    
  },

  actions: {

    clear(){
      this.db = []
    },

      // refresh
      async refresh(){
          let res = await api.send('team/list',{})
          if (res.error) return   
          this.db = res.resp || []
          return {}
      },
      
      // create simple (add attr)
      // async create(cat, sub, list, active){
      //     let res = await api.send('attr/create',{cat, sub, list, active})
      //     if (res.error) return res
      //     this.refresh()
      //     return res
      // },
      
      // create full model {name, cat, title...}
      // async createModel(model){
      //     let res = await api.send('attr/create/model',model)
      //     if (res.error) return res
      //     this.refresh()   
      //     return res
      // },
      

      // update
      // async update(id, k, v){
      //     let res = await api.send('attr/update',{id, k, v})
      //     if (res.error) return res
      //     this.refresh()   
      //     return res
      // },

      // delete
      //   async delete(id){
      //     let res = await api.send('attr/delete',{id})
      //     if (res.error) return res      
      //     this.refresh()   
      //     return res
      // },
  },


})




